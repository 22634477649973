// The cart storage exposes functions that allows you to query or change the
// cart. The cart is stored in local storage under the cart key. The cart is
// always serialized as JSON, but the format has changed over time.
//
// The latest version of the cart stores the retailer ID of the retailer for
// which the cart was created. When fetching the cart, we only reuse the cart in
// local storage if the user still has selected the same retailer. If not, the
// user gets an empty cart. This is because the cart contains price information
// that could be different for other retailers. This mechanism prevents us from
// from showing the prices for the old retailer. It also prevents a cart with
// multiple currencies when the user adds items to the cart as retailers with
// different currencies.
//
// The (historical) formats of the cart are as described below. Older versions
// of the cart are automatically migrated to the latest version.
//
// Version 3: (latest)
//
//   {
//     _version: 3,
//     retailerId: 42,
//     items: [
//       {
//         id: 42, // Product ID
//         commercialConditionsIdentifier: "cci",
//         categoryId: 43,
//         quantity: 3,
//         unitPrice: { amount: "94.53", currency: "EUR", currencySymbol: "€" },
//         reference: "customer reference"
//       }
//     ]
//   }
//
// Version 2:
//
//   [
//     {
//       id: 42, // Product ID
//       commercialConditionsIdentifier: "cci",
//       categoryId: 43,
//       quantity: 3,
//       unitPrice: { amount: "94.53", currency: "EUR", currencySymbol: "€" },
//       reference: "customer reference"
//     }
//   ]
//
// Version 1:
//
//   { 42: 3 } // { [Product ID]: Quantity }

import { compose, find, isObject, pick, reject, update } from "lodash/fp";

const cartWithItems = items => ({
  _version: 3,
  retailerId: getCurrentRetailerId(),
  items,
});

const getCartVersion = cart => {
  if (cart.hasOwnProperty("_version")) return cart._version;
  if (isObject(cart)) return 2;
  else return 1;
};

const upgradeCartVersion1ToVersion2 = legacyOrderItemDetails => cart =>
  getCartVersion(cart) == 1
    ? Object.entries(cart).map(entry => {
        const id = parseInt(entry[0]);
        const quantity = entry[1];

        const reference = (
          Object.values(legacyOrderItemDetails).find(
            ({ itemId }) => itemId === id
          ) || {}
        ).reference;

        return { id, quantity, reference };
      })
    : cart;

const upgradeCartVersion2ToVersion3 = cart =>
  getCartVersion(cart) == 2 ? cartWithItems(cart) : cart;

const removeZeroQuantityItems = update("items", items =>
  items.filter(({ quantity }) => quantity > 0)
);

const legacyOrderItemDetails =
  JSON.parse(localStorage.getItem("orderitem_details")) || {};

const normalizeCart = compose(
  removeZeroQuantityItems,
  upgradeCartVersion2ToVersion3,
  upgradeCartVersion1ToVersion2(legacyOrderItemDetails)
);

const loadFromLocalStorage = () => JSON.parse(localStorage.getItem("cart"));

const getItemIndex = pick(["id", "commercialConditionsIdentifier"]);

export const list = () => {
  const emptyCart = cartWithItems([]);
  const existingCart = normalizeCart(loadFromLocalStorage() || emptyCart);

  const cart =
    existingCart.retailerId === getCurrentRetailerId()
      ? existingCart
      : emptyCart;

  return cart.items;
};

export const add = itemToAdd => {
  const itemIndex = getItemIndex(itemToAdd);
  const currentItems = list();
  const currentItem = find(itemIndex, currentItems);

  const nextItem = currentItem
    ? { ...itemToAdd, quantity: currentItem.quantity + itemToAdd.quantity }
    : itemToAdd;

  persist([nextItem, ...reject(itemIndex, currentItems)]);
};

export const persist = items => {
  const cart = cartWithItems(items);
  const serializedCart = JSON.stringify(cart);

  localStorage.setItem("cart", serializedCart);
};
